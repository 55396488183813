import React from 'react';
import { Link } from 'gatsby';
import Button from './button';

import styled from 'styled-components'

const ConfirmationContainer = styled.div`
    text-align: center;
    padding: 50px;
    font-family: 'Open Sans';
`

const Title = styled.h1`
    font-size: 32px;
    font-weight: bolder;
`

const Copy = styled.p`
    padding: 10px;
    color: rgba($color: #FFFFFF, $alpha: 0.9);
`
const Confirmation = props => (
    <ConfirmationContainer>
        <Title>Thank you for your interest!</Title>
        <Copy>We've received your message and will be in contact with you soon!</Copy>
        <Link to="/"><Button title="Go Home" /></Link>
    </ConfirmationContainer>
)

export default Confirmation