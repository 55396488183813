import React, { Component } from "react";
import Layout from "../components/layout";

//NPM
import styled from "styled-components";
import SectionIntro from "../components/section-intro";
import Loader from "react-loader-spinner";
import Confirmation from "../components/confirmation";
import SEO from "../components/seo";
import { addEntry } from "../services/parse-bridge";
import { colors } from "../colors/colors";

//Components

const VipForm = styled.div`
  max-width: 700px;
  margin: 40px auto;
`;
const VipFormGroup = styled.div`
  padding: 20px;
`;
const Form = styled.form``;
const FormFields = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px;
  grid-row-gap: 20px;
`;
const FormLabel = styled.label`
  border-radius: 0;
  font-family: "Poppins", sans-serif;
  color: ${colors.copy};
  font-weight: bold;
  font-size: 12px;
`;
const FormInput = styled.input`
  border-radius: 3px;
  font-family: "Poppins", sans-serif;
  color: black;
  padding: 10px 10px;
  border-radius: 1px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 16px;
`;
const FormFile = styled.input``;
const FormTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 5px 10px;
  font-family: "Poppins", sans-serif;
`;
const FormButton = styled.button`
  margin: 20px 0;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-family: "Poppins";
  font-weight: 600;
  display: inline-block;
  padding: 15px 25px;
  background-color: ${colors.red};
  border: 0;
  cursor: pointer;

  :hover {
    filter: brightness(1.1);
  }
  :focus {
    filter: brightness(1.1);
    outline: none;
  }
`;
const Spinner = styled.div`
  text-align: center;
  margin: 20px 0;
`;
class JoinPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      email: "",
      igHandle: "",
      aboutYou: "",
      fullBodyImage: undefined,
      imgUrl: "",
      isUploading: false,
      progress: 0,
      complete: false,
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  createFlyer = (e) => {
    //A file was selected
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      //Create the parse file
      this.setState({
        fileSelected: true,
        fullBodyImage: e.target.files[0],
      });
      //We can handle the upload here or on hitting the upload button
    } else {
      console.log("No file selected");
      this.setState({ fileSelected: false });
    }
  };

  formFilled = () => {
    const { fullName, email, igHandle, aboutYou, fullBodyImage } = this.state;

    if (
      fullName === "" ||
      email === "" ||
      igHandle === "" ||
      aboutYou === "" ||
      fullBodyImage === undefined
    ) {
      return false;
    } else {
      return true;
    }
  };

  handleSubmit(event) {
    event.preventDefault();

    if (!this.formFilled()) {
      alert("Please fill all fields!");
      return;
    }

    const { fullName, email, igHandle, aboutYou, fullBodyImage } = this.state;
    this.setState({ loading: true });
    event.preventDefault();
    addEntry(fullName, email, igHandle, aboutYou, fullBodyImage).then((val) => {
      this.setState({ loading: false });
      if (val === true) {
        // alert('Upload Successful!');
        this.setState({ complete: true });
        //Tell them we need to vet the event and contact them.
      } else {
        alert("Something went wrong with your uplaod. Try again");
      }
    });
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Join The Team"
          description="Do you have what it takes to be a YUP Life member? Sign up now!"
          keywords={[
            `join`,
            `yup`,
            `life`,
            `FOC`,
            `Festival`,
            `Colour`,
            `Backyard`,
            `Melee`,
            `Events`,
            `Fete`,
            `Trinidad`,
            `Carnival`,
            `Party`,
            `Fete`,
          ]}
        />

        <SectionIntro
          title="Join the Team"
          copy="Do you think you have what it takes to be a part of the YUP Life Family? Fill the form below and we will get in contact with you!"
        />
        {this.state.complete ? (
          <Confirmation />
        ) : (
          <VipForm>
            <VipFormGroup>
              <Form onSubmit={this.handleSubmit}>
                <FormFields>
                  <>
                    <FormLabel>Full Name</FormLabel>
                    <FormInput
                      type="text"
                      name="fullName"
                      placeholder="John Doe"
                      onChange={this.updateInput}
                      value={this.state.fullName}
                    />
                  </>
                  <>
                    <FormLabel>Email Address</FormLabel>
                    <FormInput
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="email@user.com"
                      onChange={this.updateInput}
                      value={this.state.email}
                    />
                  </>
                  <>
                    <FormLabel>IG Handle</FormLabel>
                    <FormInput
                      type="text"
                      className="form-control"
                      name="igHandle"
                      placeholder="@username"
                      onChange={this.updateInput}
                      value={this.state.igHandle}
                    />
                  </>
                  <>
                    <FormLabel>Please Upload a Full Body Picture</FormLabel>
                    <FormFile
                      type="file"
                      name="fullBodyImage"
                      id="fullBodyImage"
                      onChange={this.createFlyer}
                    />
                  </>
                  <>
                    <FormLabel>
                      Why do you think you would make a good addition to our
                      team?
                    </FormLabel>
                    <FormTextarea
                      name="aboutYou"
                      className="form-control"
                      onChange={this.updateInput}
                      value={this.state.aboutYou}
                    />
                  </>
                  <>
                    <FormButton type="submit">Submit</FormButton>
                  </>
                </FormFields>
                {this.state.loading ? (
                  <Spinner>
                    <Loader
                      type="ThreeDots"
                      color="#B4271D"
                      height="50"
                      width="50"
                    />
                  </Spinner>
                ) : null}
              </Form>
            </VipFormGroup>
          </VipForm>
        )}
      </Layout>
    );
  }
}

export default JoinPage;
